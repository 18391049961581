import Colors from "../../../../assets/Colors";

export const LocationUpdateStyles = {
    headerText: {
        fontSize: '22px',
        fontWeight: '700',
        lineHeight: '28px',
        letterSpacing: '0.48px',
        textAlign: 'left',
        color: Colors.napaBlue

    },
    paragraph1: {
        fontFamily: 'NAPA SANS',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0.45px',
        textAlign: 'left'
    },
    invoiceText: {
        fontSize: '18px',
        fontWeight: '700',
        lineHeight: '24px',
        letterSpacing: '0.32px',
        textAlign: 'left',
        color: Colors.napaGrey2
    },
    companyNameText: {
        fontSize: '22px',
        fontWeight: '400',
        lineHeight: '28px',
        letterSpacing: '0.48px',
        textAlign: 'left',
        color: Colors.napaBlue
    },
    smallText: {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '19.5px',
        textAlign: 'left',
        color: Colors.napaGrey4
    },
    smallBold: {
        fontSize: '12px',
        fontWeight: '700',
        lineHeight: '22px',
        letterSpacing: '0.36px',
        textAlign: 'left'
    },
    addLocation: {
        fontFamily: 'NAPA SANS',
        fontSize:'12px',
        fontWeight: '400',
        lineHeight: '15px',
        letterSpace: '0.42px',
        textAlign: 'left'

    },
    deliveryNotes :{
        fontFamily: 'NAPA SANS',
        fontSize: '12px',
        fontWeight: '700',
        lineHeight: '22px',
        letterSpacing: '0.37px',
        textAlign: 'left'
    },
    address :{
        fontFamily: 'NAPA SANS',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0.42px',
        textAlign: 'left',
        color:Colors.napaGrey
    },
    confirmButton: {
         marginTop:'30px'
    },
    closeButton: {
        padding: '0 8px 0 8px'
    }
}