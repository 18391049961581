import dayjs from 'dayjs'
import { featureConfiguration } from '../types/FeatureConfigurationTypes'

export const checkIsApac = (features: featureConfiguration[]): boolean => {
  const region = features?.find((feature: featureConfiguration) => feature.featureName === 'region')
  return region?.value === 'apac' ? true : false
}

export const isApac = process.env.REACT_APP_REGION === 'apac'

export const isNonApac = process.env.REACT_APP_REGION !== 'apac'

export const apacDateFormat=(date: string | number | Date | dayjs.Dayjs | null | undefined)=> dayjs(date).format('DD/MM/YYYY')
