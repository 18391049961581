import Colors from '../../assets/Colors'

export const routeBuilderStyles = {
  routeBuilderTitle: {
    color: '#001489',
    fontWeight: '400',
    fontSize: '28px',
    lineHeight: '38px',
    letterSpacing: '0.48px',
    marginBottom: '25px'
  },
  routeBuilderTitleGrid: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  allRoutesPaper: {
    borderRadius: '5px',
    boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.25)',
    paddingY: '16px',
    paddingX: '12px'
  },
  allRoutesTypography: {
    fontWeight: '700',
    fontSize: '22px',
    marginBottom: 2,
    letterSpacing: '0.48px',
    color: Colors.napaBlue
  },
  invoicesTabFont: {
    marginLeft: '20px',
    marginRight: '20px',
    fontFamily: 'NAPA SANS',
    textTransform: 'none',
    fontSize: '16px',
    borderBottomColor: '1px',
    paddingBottom: '1px',
    paddingInline: 0,
    borderRadius: 0
  },
  routeTabFont: {
    marginRight: 20,
    textTransform: 'none',
    fontFamily: 'NAPA SANS',
    fontSize: '16px',
    borderBottomColor: '1px',
    paddingBottom: '1px',
    paddingInline: 0,
    borderRadius: 0
  },
  gridContainer:{
    marginTop: 6,
    paddingInline: 30
  },
  gridItem:{
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    pointerEvents: 'none' //to make map markers clickable if under this section.
  },
  gridMapItem:{
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start'
  },
  gridRouteBuilder:{
    zIndex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    marginLeft: '-14px'
  },
  addInvoiceGrid:{
    zIndex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'right'
  },
  addInvoiceButton:{
    width: '194px',
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: '#F6F8F6',
    },
    '&:disabled': {
      borderColor: '#f4f4f4',
    },
    pointerEvents: 'auto'
  },
  createRouteButton:{ width: '194px', marginLeft: '10px', pointerEvents: 'auto' },
  dialogButton:{
    width: '48%'
  },
  dialogAction:{
    padding: '16px'
  },
  routeReview:{
    color: Colors.napaBlue,
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '24px',
    textAlign: 'center',
    fontFamily: 'NAPA SANS'
  },
  dialogTitle:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  driverTitle:{
    color: Colors.black,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'NAPA SANS',
    paddingBottom: '5px'
  },
  divider:{ marginRight: 10 },
  addressLine2:{
    color: Colors.napaGrey,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'NAPA SANS'
  },
  addressName:{
    color: Colors.napaBlue,
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'NAPA SANS'
  },
  showMap: {
    fontSize: '14px',
    marginLeft: '10px',
    cursor: 'pointer',
    color: '#0066CB',
    fontFamily: 'NAPA SANS',
    pointerEvents: 'auto' as React.CSSProperties['pointerEvents'],
  },
  moreActions: {
    width: '194px', 
    marginLeft: '10px', 
    pointerEvents: 'auto'
  },
  downIcon: {
    color: Colors.white
  },
  marginT5: {
    marginTop: '5px'
  },
  invoiceText: {
    color: Colors.napaBlue,
    fontSize: '14px',
    fontWeight: '400'
  },
  marginR5: {
    marginRight: '5px'
  }
}
