
export const deliveryLocationSelectStyles = {
  addLocation: {
    fontSize: '12px',
    marginLeft: '10px',
    cursor: 'pointer',
    color: '#0066CB',
    fontFamily: 'NAPA SANS',
    textDecoration: 'underline',
    height: '22px',
    display: 'flex',
    justifyContent: 'start',
    marginBottom: '6px',
    alignItems: 'center'
  },
  icon: {
    marginRight: '6px'
  },
  selectMenuItemLine1: {
    color: '#333333',
    fontSize: '12px',
    fontWeight: '600',
    display: 'block',
  },
  selectMenuItemLine2: {
    display: 'block',
    color: '#333333',
    fontSize: '12px',
    fontWeight: '300',
  },
  deliveryLocation: {
    margin: '0',
    marginBottom: '6px',
    fontFamily: 'NAPA SANS',
    fontSize: '12px',
    height: '22px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center'
  },
  selectHeader: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center'
  },  
  addressComponent: {
    fontSize: '12px',
    fontWeight: '400',
    paddingLeft: '8px',
    paddingBottom: '4px'
  },
  addressComponentTwo: {
    fontSize: '12px',
    fontWeight: '400',
    paddingLeft: '16px',
    paddingBottom: '4px'
  },
  addressHeader: {
    fontSize: '12px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    paddingRight: '8px',
    paddingBottom: '8px'
  },
  addressSection: {
    paddingBottom: '4px'
  }
}
