import mapboxgl from 'mapbox-gl'
import { LegacyRef, MutableRefObject, useEffect, useRef } from 'react'
import { addressMarker, dropPinMarker } from '../../../components/MapView/MapMarkers'
import { CreateRoutePreviewMapStyles } from '../../RouteBuilder/components/CreateRoute/CreateRoutePreviewMapStyles'

type Props = {
  lngLat: [number, number] | null
  setLngLat: (value: [number, number]) => void
  someAddress: {latitude: number, longitude: number}
  isDraggable: boolean
};

const DropPinMap = ({ lngLat, setLngLat, someAddress, isDraggable}: Props) => {
  const mapContainer = useRef() as MutableRefObject<HTMLElement>
  const map = useRef<mapboxgl.Map>()
  const customerLocation: [number, number] = [someAddress.longitude as number, someAddress.latitude as number]
  const pinMarker = useRef<mapboxgl.Marker[]>([]);

  useEffect(() => {
    if (map.current) return
    const [usCenterLng, usCenterLat] = [-98.5795, 39.8283];
    map.current = new mapboxgl.Map({
      container: mapContainer.current as HTMLElement,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: customerLocation || [usCenterLng, usCenterLat],
      zoom: 15,
      minZoom: 10
    })
  })

  const plotMapAttributes = () => {
    try {
      const addressPin = addressMarker('30px');
      const dropPin = dropPinMarker();
      // Clear existing markers when activeUsers change
      pinMarker.current.forEach(marker => marker.remove());
      pinMarker.current.length = 0;
      const bounds = new mapboxgl.LngLatBounds();

      // Plot markers
      if (map.current) {

        addressPin.setLngLat(customerLocation).addTo(map.current);
        bounds.extend(customerLocation)
        pinMarker.current.push(addressPin);

        if(!!lngLat){
          dropPin.setLngLat(lngLat).addTo(map.current)
          if(isDraggable) {
            dropPin.setDraggable(true);
            dropPin.on('dragend', () => {
              const newLngLat = dropPin.getLngLat()
              setLngLat([+newLngLat.lng.toFixed(6), +newLngLat.lat.toFixed(6)])
            });
          }
          pinMarker.current.push(dropPin);
          bounds.extend(lngLat)
        }

        map.current?.setMaxZoom(18).fitBounds(bounds, { padding: 70, duration: 0 });
      }

    } catch (error) {
      console.error(error);
    }
  };
  //eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {plotMapAttributes()}, [isDraggable, someAddress]);

  return (
    <div style={{...CreateRoutePreviewMapStyles.dropPinMap, position: 'relative'}} ref={mapContainer as LegacyRef<HTMLDivElement>}>
    </div>
  )
}

export default DropPinMap
