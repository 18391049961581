import { Box, Button, Checkbox, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"
import { AddressAutofill } from '@mapbox/search-js-react'
import { AddressInfo, americaStateList, CustomerAddressPayload, toCustomerAddressPayload, CustomerInfo, formatLatLang } from "../../../../utilities/services/CustomerService/CustomerService"
import { ReactComponent as PinIcon } from '../../../../assets/pin.svg'
import { ReactComponent as DropOffPin } from '../../../../assets/drop_off_pin.svg'
import { ReactComponent as NotesIcon } from '../../../../assets/notes.svg'
import { customerDetailsStyles } from "../CustomerDetailsStyles"
import React, { useContext, useEffect, useState } from "react"
import { LanguageContext } from "../../../../utilities/contexts/LanguageContext"
import language from "../../../../language/language"
import { StoreContext } from "../../../../utilities/contexts/StoreContext";

interface InputProps {
    customerInfo: CustomerInfo,
    addressInfo: AddressInfo,
    onSave: (addressUpdate: CustomerAddressPayload) => void
    onCancel: () => void,
    lngLat: [number, number] | null
    setLngLat: (value: [number, number] | null) => void
    index: number
    pinLabel : string | null
    blackPinLngLat : [number, number] | null
    setBlackPinLngLat : (value: [number, number] | null) => void
}

const CustomerAddress: React.FC<InputProps>  = ({ customerInfo, addressInfo, onSave, onCancel, lngLat, setLngLat, index, pinLabel, blackPinLngLat, setBlackPinLngLat}) => {
    const { currentLanguage } = useContext(LanguageContext)
    const { currentStore } = useContext(StoreContext)

    const [formValues, setFormValues] = useState<CustomerAddressPayload>(toCustomerAddressPayload(
        customerInfo.customerId, addressInfo, currentStore
    ))
    const [isFormValid, setFormValid] = useState<boolean>();
    const [needsLineTwo, setNeedsLinetwo] = useState<boolean>(!!formValues.line2 && formValues.line2 !== "");

    useEffect(()=>{
        //reset pin label on drop pin change
        setFormValues(state => {
            return ({
            ...state,
            deliveryGeocodes: !!lngLat && !!state.deliveryGeocodes && state.deliveryGeocodes.length>0 ? 
            [{...state.deliveryGeocodes[0],
                latitude: `${lngLat[1]}`,
                longitude: `${lngLat[0]}`,
                isDefault: 'Y',
            }] : (!!lngLat ? [{
                    latitude: `${lngLat[1]}`,
                    longitude: `${lngLat[0]}`,
                    isDefault: 'Y',
                    pinLabel: '',
                    pinId: undefined
                }] : null)
        })
    })
        
    }, [lngLat])

    const handleChange = (event: {target: any}) => {
        let needsToNullifyGeocodes : boolean = false;
        const eventName = event.target?.name.includes('line1') ? 'line1' : (event.target?.name.includes('line2') ? 'line2' : event.target?.name)
        if(event.target?.name.includes('city') || event.target?.name.includes('zip') || event.target?.name.includes('state') || event.target?.name.includes('line1')) {
            needsToNullifyGeocodes = true
        }
        setFormValues({
            ...formValues,
            latitude: needsToNullifyGeocodes ? '' : formValues.latitude,
            longitude: needsToNullifyGeocodes ? '' : formValues.longitude,
            [eventName]: event.target?.value
        })
    }

    const handleDeliveryChange = (event: {target: any}) => {
        if(!!formValues.deliveryGeocodes && formValues.deliveryGeocodes.length > 0){      
            setFormValues({
                ...formValues,
                deliveryGeocodes: [
                    {...formValues.deliveryGeocodes[0],
                        [event.target?.name]: event.target?.value
                    }
                ]
            })
        }
    }

    useEffect(()=>{
        setNeedsLinetwo(!!formValues.line2 && formValues.line2 !== "")
        const validateForm = () =>{
            const requiredFields = ['line1', 'city', 'state', 'zip', 'country', 'addressLabel']
            let isValid = true;
            Object.keys(formValues)
                    .filter(key => requiredFields.indexOf(key) !== -1)
                    .forEach((key) => {
                        if (isValid) isValid = !!(formValues as any)[key] && (formValues as any)[key] !== ''
                    })
                    if(isValid && (!!!formValues.latitude || formValues.latitude === "" || !!!formValues.longitude || formValues.longitude === "")) isValid = false
            if (isValid && !!formValues.latitude && !!formValues.deliveryGeocodes && (!!!formValues.deliveryGeocodes?.at(0)?.pinLabel || formValues.deliveryGeocodes?.[0]?.pinLabel === '')) isValid = false
            setFormValid(isValid)
        }
        validateForm()
    }, [formValues, lngLat])

    const handleAutofillRetrieve = (response: any) => {
        const addressLine1 = response.features[0]?.properties?.address_line1;
        const city = response.features[0]?.properties?.place;
        const stateCode = response.features[0]?.properties?.region_code;
        const zipcode = response.features[0]?.properties?.postcode;
        const coordinates = response.features[0]?.geometry.coordinates;
        // const street = response.features[0]?.properties?.street || 'none'
        setBlackPinLngLat([coordinates[0], coordinates[1]])
        setLngLat(null)
        setFormValues({
            ...formValues,
            latitude: coordinates[1],
            longitude: coordinates[0],
            line1: addressLine1,
            line2: null,
            city: city,
            state: stateCode,
            zip: zipcode,
            deliveryGeocodes: null,
            notes: null 
        })
    }

    return <Box display="flex" flexDirection="column" gap={"16px"} alignItems="start">
        <Typography sx={customerDetailsStyles.typoHeader5}>
        {(language as any)[currentLanguage].location} {index + 1}: {addressInfo?.addressLabel} {!!pinLabel && `- ${pinLabel}`}
        </Typography>
        <Box>

            <Typography sx={customerDetailsStyles.paragraphBold2} display={"flex"} alignItems={"center"} gap={"6px"}>
                <PinIcon />
                {(language as any)[currentLanguage].address}
            </Typography>
            <Box sx={{ paddingLeft: '20px' }}>
                <Box display="flex" alignItems="start" flexDirection="column" width="100%">
                    <Typography sx={customerDetailsStyles.paragraph2}>
                        {(language as any)[currentLanguage].locationName}
                    </Typography>
                    <OutlinedInput placeholder="ex: business - site #2" size="small" fullWidth
                        name="addressLabel"
                        value={formValues.addressLabel}
                        onChange={handleChange}></OutlinedInput>
                </Box>
                <Box display="flex" alignItems="start" flexDirection="column" width="100%">
                    <Typography sx={customerDetailsStyles.paragraph2}>
                        {(language as any)[currentLanguage].addressLine} 1
                    </Typography>
                    <AddressAutofill
                     accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''}
                        onRetrieve={handleAutofillRetrieve}
                    >
                    <TextField size="small" fullWidth 
                        name="line1"
                        value={formValues.line1}
                        autoComplete="address-line1"
                        onChange={handleChange}></TextField>
                    </AddressAutofill>
                    {!needsLineTwo && <Button sx={customerDetailsStyles.paragraph2Link} onClick={() => setNeedsLinetwo(true)} variant="text">
                        {(language as any)[currentLanguage].optionalAddress}
                    </Button>}
                </Box>
                {!!needsLineTwo && <Box display="flex" alignItems="start" flexDirection="column" width="100%">
                    <Typography sx={customerDetailsStyles.paragraph2}>
                        {(language as any)[currentLanguage].addressLine} 2
                    </Typography>
                    <OutlinedInput placeholder="" size="small" fullWidth
                        name="line2"
                        value={formValues.line2}
                        onChange={handleChange}></OutlinedInput>
                </Box> }
                <Box display="flex" flexDirection="row" justifyContent="space-between" gap="16px" sx={{ textAlign: 'left' }}>
                    <Box>
                        <Typography sx={customerDetailsStyles.paragraph2}>
                            {(language as any)[currentLanguage].city}
                        </Typography>
                        <TextField size="small"
                            name="city"
                            value={formValues.city}
                            onChange={handleChange}></TextField>
                    </Box>
                    <Box sx={{ width: '100px' }}>
                        <Typography sx={customerDetailsStyles.paragraph2}>
                            {(language as any)[currentLanguage].state}
                        </Typography>
                        <Select sx={{ width: '100%' }} size="small"
                            name={"state"}
                            value={formValues.state}
                            onChange={handleChange}>
                            {americaStateList.map((state)=>{
                                return <MenuItem value={state.value}>{state.label}</MenuItem>
                            })}
                        </Select>
                    </Box>
                    <Box sx={{ width: '150px' }}>
                        <Typography sx={customerDetailsStyles.paragraph2}>
                            {(language as any)[currentLanguage].zipCode}
                        </Typography>
                        <TextField size="small"
                            name={"zip"}
                            value={formValues.zip}
                            onChange={handleChange}></TextField>
                    </Box>
                </Box>
                <Box display="flex" alignItems="start" flexDirection="column" width="100%">
                    <Typography sx={customerDetailsStyles.paragraph2}>
                            {(language as any)[currentLanguage].phoneNumber}
                    </Typography>
                    <OutlinedInput placeholder="" size="small" fullWidth
                        name="phoneNumber"
                        value={formValues.phoneNumber}
                        onChange={handleChange}></OutlinedInput>
                </Box>
            </Box>
        </Box>
        <Box>
            <Box display="flex" flexDirection="row" alignItems="center">
                <Checkbox sx={{ paddingLeft: '0px' }} checked={!!!lngLat} onChange={(event)=>{
                    if(!!event.target.checked){
                        setLngLat(null)
                    }
                    else {
                        setLngLat(blackPinLngLat)
                    }
                }}></Checkbox>
                <Typography sx={customerDetailsStyles.paragraph2}>
                    {(language as any)[currentLanguage].dropOffSameLocation}
                </Typography>
            </Box>
            {!!lngLat && <Box display="flex" flexDirection="column" paddingLeft="20px" gap="8px">
                <Typography sx={customerDetailsStyles.paragraphBold2} display={"flex"} alignItems={"center"} gap={"6px"}>
                    <DropOffPin />
                    {(language as any)[currentLanguage].dropOffSpot}
                </Typography>
                <Box display="flex" flexDirection="column" paddingLeft="20px" gap="8px">
                    <Typography sx={customerDetailsStyles.paragraph2}>
                        {(language as any)[currentLanguage].dropPinMapDesc}
                    </Typography>
                    <Box display="flex" flexDirection="row">
                        <Typography sx={customerDetailsStyles.paragraphBold2}>
                            {(language as any)[currentLanguage].coordinates}
                        </Typography>
                        <Typography sx={customerDetailsStyles.paragraph2}>{formatLatLang(!!lngLat ? lngLat[1] : 0,!!lngLat ? lngLat[0] : 0)}</Typography>
                    </Box>
                    <Box width="100%">
                        <Typography sx={customerDetailsStyles.paragraphBold2}>
                            Drop-off name (required)
                        </Typography>
                        <OutlinedInput fullWidth size="small" placeholder="ex: back of store"
                            name={'pinLabel'}
                            value={formValues.deliveryGeocodes?.at(0)?.pinLabel}
                            onChange={handleDeliveryChange}></OutlinedInput>
                    </Box>
                </Box>
            </Box>}
        </Box>

        <Box width="100%">
            <Typography sx={customerDetailsStyles.paragraphBold2} display={"flex"} alignItems={"center"} gap={"6px"}>
                <NotesIcon />
                {(language as any)[currentLanguage].deliveryNotes}
            </Typography>
            <Box sx={{ paddingLeft: '20px' }}>
                <OutlinedInput fullWidth size="small" placeholder="ex: all deliveries at back entrance"
                    name={'notes'}
                    value={formValues.notes}
                    onChange={handleChange}></OutlinedInput>
            </Box>
        </Box>
        <Box sx={{ width: '100%' }} display="flex" justifyContent="space-between">
            {/*NOT FOR PILOT <Button size="small" variant="contained" sx={{ background: Colors.napaRed4 }}
                disabled={!addressInfo.addressId}>
                {(language as any)[currentLanguage].delete} {(language as any)[currentLanguage].location}
            </Button> */}
            <Button sx={{width: "70px"}} size="small" variant="outlined" onClick={onCancel}>
                {(language as any)[currentLanguage].cancel}
            </Button>
            <Button sx={{width: "70px"}} size="small" variant="primary" onClick={()=>{
                    onSave(formValues)
                }} disabled={!isFormValid}>
                {(language as any)[currentLanguage].save}
            </Button>
        </Box>
    </Box>
}

export default CustomerAddress